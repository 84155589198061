
$(document).ready(function() {

	// MENU
	$('#left-menu').sidr({
	  name: 'navigation',
	  side: 'left',
	  source: '#nav'
	});

	$('#right-menu').sidr({
	  name: 'group-btn',
	  side: 'right',
	  source: '#group-nav'
	});

	$("#latest-properties-slick").on('setPosition', function() {
		var width = 100 / $("#latest-properties-slick ul.slick-dots").children().length;
		$('#latest-properties-slick .slick-dots li').css('width', width+'%');
	}).not('.slick-initialized').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: true,
		autoplay: true,
		prevArrow: '<button class="slick-prev" aria-label="Previous" type="button">«</button>',
		nextArrow: '<button class="slick-next" aria-label="Next" type="button">»</button>',
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToScroll: 3,
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 650,
				settings: {
					slidesToScroll: 2,
					slidesToShow: 2,
					dots: false,
				},
			},
		],
	});


	$('tr[data-href]').on('click', function() {
		window.document.location = $(this).data('href');
	});

	// SLIDER(S)
	$('.big-slider').flickerplate();

	$('.thumb-caro').flickerplate({
		arrows: false,
		arrows_constraint: false,
		auto_flick: false,
		block_text: false,
		dot_alignment: 'right',
		dot_navigation: true
	});

	$('.img-caro').flickerplate({
		arrows: true,
		arrows_constraint: false,
		auto_flick: true,
		block_text: false,
		dot_alignment: 'right',
		dot_navigation: true
	});

	// CAROUSEL
	$("#latest-properties").owlCarousel({
		items: 4,
		itemsDesktop: [992,4],
		itemsTablet: [768,3],
		itemsMobile: [520,2],
		lazyLoad : true,
		navigation : true
	});

	jQuery('.commercial_development_slider').slick({
		infinite: true,
		centerMode: false,
		centerPadding: '40px',
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1350,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});

	// RESPONSIVE MENUS
	$(".resp-menu").flexNav();

	// SLIDE TOGGLE
	$('.toggle').click(function(){
		$(this).next('.panel').slideToggle('fast');
	});

	$('.map-toggle').click(function(){
		$(this).next('.map-panel').slideToggle('fast');

		initialize();
	});

	// CHOSEN JS
	$(".chosen-select").chosen();

	// LIGHTBOX
	$(".fancybox").fancybox({
		openEffect	: 'none',
		closeEffect	: 'none'
	});

	$(".videoPopup").fancybox({
		openEffect	: 'none',
		closeEffect	: 'none',
		maxWidth	: 800,
		maxHeight	: 465
	});

	// STICKY KIT
	$("#left-sidebar").stick_in_parent({
		recalc_every: 1,
		offset_top: 20
	});

	// Resize slider dots
	var numDots = $('.owl-page').length;
	var numDotsPerc = parseInt(100/numDots);

	$('.owl-page').width(numDotsPerc +'%');

});
